import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndpoint } from '../config';
import { parse } from 'papaparse'; // You can use PapaParse for CSV parsing
import './Members.css'

const DataDisplayPage = () => {
    const [data, setData] = useState(null);
    const [csvData, setCsvData] = useState(''); // Store raw CSV data
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${apiEndpoint}/adminGetUsers`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                    },
                });

                const decodedCsv = atob(response.data);
                setCsvData(decodedCsv);

                parse(decodedCsv, {
                    header: true,
                    skipEmptyLines: true,
                    transformHeader: header => header.trim(),
                    complete: (results) => {
                        setUsers(results.data);
                    }
                });
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const handleDownload = () => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'users.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <h1>User Data</h1>
            <button onClick={handleDownload}>Download CSV</button>
            <div className="table-container">
            <table>
                <thead>
                <tr>
                    {/* Render Table Headers */}
                    {users[0] && Object.keys(users[0]).map((key, index) => (
                        <th key={index}>{key}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {/* Render Table Rows */}
                {users.map((user, index) => (
                    <tr key={index}>
                        {Object.values(user).map((value, valueIndex) => (
                            <td key={valueIndex}>{value}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
                </div>
        </div>
    );
};

export default DataDisplayPage;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Logo from './assets/Crest.png';
import './App.css';
import CheckIn from './pages/CheckIn';
import Members from './pages/Members';
import { trySilentLogin } from './auth/AuthUtils';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { authorize } from './auth/authentication';

function App() {
    const [userEmail, setUserEmail] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false); // New state to track admin status

    function decodeIdTokenAndSetStates() {
        const idToken = localStorage.getItem('id_token');
        if (idToken) {
            const payload = JSON.parse(atob(idToken.split('.')[1]));
            const email = payload.email;
            const groups = payload['cognito:groups'] || [];

            setUserEmail(email);
            setIsAdmin(groups.includes("367a5703-bc5c-452c-a37c-597aa0659e1c")); // Check for admin group
            localStorage.setItem('email', email);
            localStorage.setItem('groups', JSON.stringify(groups));
        }
    }

    useEffect(() => {
        trySilentLogin(setUserEmail);
        decodeIdTokenAndSetStates();
    }, []);

    function logout() {
        // Clear local storage
        localStorage.removeItem('id_token');
        localStorage.removeItem('email');
        localStorage.removeItem('groups');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('userDetails');

        localStorage.clear()

        // Reset application state
        setUserEmail(null);

        // Redirect the user to the home page
        window.location.href = '/';
    }



    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <div className="header-top">
                        <img src={Logo} alt="Company Logo" className="App-logo" />

                        {userEmail ? (
                            <button className="logout-button" onClick={logout}>
                                Logout
                            </button>
                        ) : (
                            <button className="login-button" onClick={authorize}>
                                Login
                            </button>
                        )}
                    </div>
                    <nav>

                            <>
                                <Link className="nav-link" to="/checkIn">CheckIn</Link>
                                <Link className="nav-link" to="/members">Members</Link>
                            </>
                    </nav>
                </header>
                <Routes>
                    <Route path="/checkin" element={<CheckIn />} />
                    <Route path="/members" element={<Members />} />
                    {/* Other routes */}
                </Routes>
                <footer className="App-footer">
                    {/* Footer content */}
                </footer>
            </div>
        </BrowserRouter>
    );
}

export default App;

import { fetchTokens, silentLogin } from './authentication';
import queryString from 'query-string';
import atob from 'atob';

export function decodeIdToken(idToken) {
    const payload = idToken.split('.')[1];
    const decoded = JSON.parse(atob(payload));
    return decoded.email;
}

export async function trySilentLogin(setUserEmail, setGroupId, setCustomAlias, setCustomPronouns) {
    const { code } = queryString.parse(window.location.search);

    if (code) {
        try {
            const data = await fetchTokens(code);
            console.log("Fetched Tokens:", data);  // Debug line
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('id_token', data.id_token);

            const email = decodeIdToken(data.id_token);
            localStorage.setItem('email', email);
            if (typeof setUserEmail === 'function') {
                setUserEmail(email);
            }

            const decoded = JSON.parse(atob(data.id_token.split('.')[1]));
            console.log("Decoded Payload:", decoded);  // Debug line

            if (decoded && decoded['cognito:groups'] && typeof setGroupId === 'function') {
                setGroupId(decoded['cognito:groups']);
            }


        } catch (error) {
            console.error('Error fetching tokens', error);
        }
    } else {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail && typeof setUserEmail === 'function') {
            setUserEmail(storedEmail);
        }

        const isSuccess = await silentLogin();
        if (isSuccess) {
            const email = decodeIdToken(localStorage.getItem('id_token'));
            if (typeof setUserEmail === 'function') {
                setUserEmail(email);
            }

            const idToken = localStorage.getItem('id_token');
            const decoded = JSON.parse(atob(idToken.split('.')[1]));
            console.log("Decoded Payload:", decoded);  // Debug line

            if (decoded && decoded['cognito:groups'] && typeof setGroupId === 'function') {
                setGroupId(decoded['cognito:groups']);
            }

        }
    }
}

import axios from 'axios';
import { clientId, redirectUri, authUrl, tokenUrl } from '../config';
import jwtDecode from 'jwt-decode';

export function generateRandomString() {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

export function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

export async function base64urlencode(str) {
    const base64 = await str.reduce((data, byte) => data + String.fromCharCode(byte), '');
    return btoa(base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function pkceChallengeFromVerifier(v) {
    const hashed = await sha256(v);
    return base64urlencode(new Uint8Array(hashed));
}

export async function authorize() {
    const codeVerifier = generateRandomString();
    const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);
    localStorage.setItem('code_verifier', codeVerifier);

    const url = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=openid`;
    window.location = url;
}

export async function fetchTokens(code) {
    const codeVerifier = localStorage.getItem('code_verifier');

    const requestBody = {
        client_id: clientId,
        redirect_uri: redirectUri,
        code,
        code_verifier: codeVerifier,
        grant_type: 'authorization_code',
    };

    try {
        const response = await axios.post(tokenUrl, requestBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('id_token', response.data.id_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);

        return response.data;
    } catch (error) {
        console.error('Failed to fetch tokens', error);
    }
}

export async function refreshAccessToken(refreshToken) {
    const requestBody = {
        client_id: clientId,
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
    };

    try {
        const response = await axios.post(tokenUrl, requestBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('id_token', response.data.id_token);

        const decodedIdToken = jwtDecode(response.data.id_token);

    } catch (e) {
        console.error('Error during token refresh', e);
    }
}

export async function silentLogin() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
        try {
            await refreshAccessToken(refreshToken);
            return true;
        } catch (e) {
            console.error('Failed to refresh token', e);
            return false;
        }
    }
    return false;
}

export function handleRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
        fetchTokens(code).then(tokens => {
            // Additional work can be done here
        }).catch(error => {
            console.error('Failed to fetch tokens', error);
        });
    }
}

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { apiEndpoint } from "../config";

const QRCodeScanner = () => {
    const scannerRef = useRef(null);
    const [scannedValue, setScannedValue] = useState(null);
    const [scannerActive, setScannerActive] = useState(true);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [scanResult, setScanResult] = useState(null); // 'success', 'failure', or null


    // Fetch events from the API
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/adminGetEvents`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                    },
                });

                // Log the raw response data
                console.log("Raw API Response:", response.data);

                // Attempt to parse the response data
                let eventData = response.data;
                if (typeof eventData === 'string') {
                    console.log("Attempting to parse string: ", eventData); // Log the string to be parsed
                    try {
                        eventData = JSON.parse(eventData);
                    } catch (error) {
                        console.error('Error parsing events data:', error);
                        return; // Exit if parsing fails
                    }
                }

                // Transform the data into a more usable format
                if (Array.isArray(eventData)) {
                    const transformedEvents = eventData.map(event => ({
                        startDateTime: event.startDateTime.S,
                        ticketNeeded: event.ticketNeeded.BOOL,
                        location: event.location.S,
                        capacity: event.capacity.S,
                        active: event.active.BOOL,
                        memberOnly: event.memberOnly.BOOL,
                        id: event.id.S,
                        createdBy: event.createdBy.S,
                        name: event.name.S,
                        endDateTime: event.endDateTime.S,
                        type: event.type.S
                    }));

                    setEvents(transformedEvents);
                } else {
                    console.error("Parsed API response is not an array:", eventData);
                }
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    // Function to post scanned data
    const postScannedData = async (userToken) => {
        if (!selectedEvent) {
            alert("Please select an event first");
            return;
        }

        const selectedEventData = events.find(event => event.id === selectedEvent);
        if (!selectedEventData) {
            alert("Selected event not found");
            return;
        }

        try {
            const response = await axios.post(`${apiEndpoint}/adminPostVolunteer`, {
                userId: userToken,
                eventId: selectedEventData.id,
                eventName: selectedEventData.name
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                }
            });

            if (response.status === 200) {
                setScanResult('success');
                setTimeout(() => {
                    setScanResult(null);
                    setScannerActive(true); // Reactivate scanner for next scan
                }, 2000); // 2 seconds delay
            } else {
                setScanResult('failure');
            }
        } catch (error) {
            setScanResult('failure');
            console.error('Error posting scanned data:', error);
        }
    };

    // Initialize QR Code Scanner
    useEffect(() => {
        let html5QrCodeScanner;
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            setScannedValue(decodedText);
            setScannerActive(false);
            html5QrCodeScanner.clear();

            postScannedData(decodedText);
        };

        const qrCodeErrorCallback = (error) => {
            console.log(`QR Code error: ${error}`);
        };

        const config = { fps: 10, qrbox: { width: 250, height: 250 } };

        if (scannerActive) {
            html5QrCodeScanner = new Html5QrcodeScanner(
                scannerRef.current.id, config, false
            );
            html5QrCodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
        }

        return () => {
            if (html5QrCodeScanner) {
                html5QrCodeScanner.clear();
            }
        };
    }, [scannerActive, events, selectedEvent]);

    // Additional styling for feedback
    const scannerStyles = {
        border: scanResult === 'success' ? '5px solid green' :
            scanResult === 'failure' ? '5px solid red' : 'none',
        // Add more styles if needed
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
            {events.length > 0 && (
                <select
                    onChange={(e) => setSelectedEvent(e.target.value)}
                    value={selectedEvent}
                    style={{ marginBottom: '20px' }}
                >
                    <option value="">Select an Event</option>
                    {events.map((event, index) => (
                        <option key={index} value={event.id}>
                            {event.name}
                        </option>
                    ))}
                </select>
            )}

            {scannerActive ? (
                <div id="qr-reader" ref={scannerRef} style={scannerStyles}></div>
            ) : (
                <div style={{ fontSize: '24px' }}>
                    Scanned Value: {scannedValue}
                </div>
            )}
        </div>
    );
};

export default QRCodeScanner;
